import { Text } from '@belong/ui';
import { HOME_PAGE_STRINGS } from 'src/features/home-page/home-page.strings';

export function SeoHint() {
  return (
    <div className="md:mt-0 container flex justify-end relative h-xs mt-xs">
      <Text as="h1" variant="p2" className="text-dark-gray absolute bottom-xs">
        {HOME_PAGE_STRINGS['seoHint']}
      </Text>
    </div>
  );
}
